export default {
  "HelloWorld": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello world!!"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello from component!"])},
    "setVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Variable"])},
    "variableCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The variable is correct"])},
    "variableIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The variable is incorrect"])}
  },
  "Header": {
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MuseumGo"])},
    "search-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
    "eng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENG"])}
  }
}