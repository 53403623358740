export default {
  "HelloWorld": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour Monde !!"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour depuis composant!"])},
    "setVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire variable"])},
    "variableCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le variable est impec'"])},
    "variableIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le variable est pas impec"])}
  },
  "Header": {
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MuseeAller"])},
    "search-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
    "eng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENG"])}
  }
}