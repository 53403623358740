<template>
  <router-view />
</template>

<script>
import "./assets/css/main.scss";
export default {
  name: "App",
  components: {},
};
</script>
